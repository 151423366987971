import { Card, Col, CollapseProps, Row, Space, Typography } from "antd";
import React, { CSSProperties, useEffect, useState } from "react";
import { theme } from "antd";
import CardSample from "./sample-card";

import { useDataQuery } from "./hooks/useData";
import style from "antd/es/affix/style";

const { Text, Link, Title, Paragraph } = Typography;

const ContentContainer = ({
  problemId = "",
  solutionId = "",
  kind = "problem",
  colorBgContainer,
  borderRadiusLG,
  setSolsExpanded,
  solsExpanded = false,
}: {
  problemId?: string;
  solutionId?: string;
  kind?: "problem" | "solution";
  colorBgContainer: string;
  borderRadiusLG: number;
  setSolsExpanded?: (expanded: boolean) => void;
  solsExpanded?: boolean;
}) => {
  const { token } = theme.useToken();

  const panelStyle: React.CSSProperties = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };

  const isDev =
    window.location.hostname.includes("dev") ||
    window.location.hostname.includes("localhost");

  const text = "wfewf";

  const getItems: (panelStyle: CSSProperties) => CollapseProps["items"] = (
    panelStyle
  ) => [
    {
      key: "1",
      label: "This is panel header 1",
      children: <p>{text}</p>,
      style: panelStyle,
    },
    {
      key: "2",
      label: "This is panel header 2",
      children: <p>{text}</p>,
      style: panelStyle,
    },
    {
      key: "3",
      label: "This is panel header 3",
      children: <p>{text}</p>,
      style: panelStyle,
    },
  ];

  const [cardData, setData] = useState<any[] | undefined>();

  const problemAttrs: any[] = useDataQuery("attrs");
  const solutionAttrs: any[] = useDataQuery("attrs_solution");
  const attrs = kind === "problem" ? problemAttrs : solutionAttrs;

  const cardsProblem = useDataQuery(`cards_problem${problemId}`, !!problemId);

  const problem = useDataQuery(`problem${problemId}`, !!problemId);

  const solution0: Record<string, any> = useDataQuery(
    `problem${problemId}_solution0`,
    !!problemId
  );

  const solution1: Record<string, any> = useDataQuery(
    `problem${problemId}_solution1`
  );

  const cardsSolution: any[] = useDataQuery(
    `cards_problem${problemId}_solution${solutionId}`,
    !!problemId && !!solutionId
  );

  const attrCards: any[] = kind === "problem" ? cardsProblem : cardsSolution;

  if (!attrCards) return <div>Loading attribute cards...</div>;
  if (!attrs) return <div>Loading attributes...</div>;
  if (!problem) return <div>Loading attributes...</div>;

  const topCardDescription =
    "#### " + problem?.title + "\n\n" + problem?.description;

  const solution0Description =
    "#### " + solution0?.title + "\n\n" + solution0?.description;

  const solution1Description =
    "#### " + solution1?.title + "\n\n" + solution1?.description;

  const problemWidth = kind === "problem" ? 12 : 6;
  const solution0Width = kind === "solution" && solutionId === "0" ? 12 : 6;
  const solution1Width = kind === "solution" && solutionId === "1" ? 12 : 6;

  const problemLink =
    kind === "problem" ? undefined : `/#/problems/${problemId}/`;

  const solution0Link =
    kind === "solution" && solutionId === "0"
      ? undefined
      : `/#/problems/${problemId}/solutions/0/`;

  const solution1Link =
    kind === "solution" && solutionId === "1"
      ? undefined
      : `/#/problems/${problemId}/solutions/1/`;

  const solsStyleFull = {
    background: colorBgContainer,
    minHeight: 200,
    padding: 12,
    borderRadius: borderRadiusLG,
    // overflowX: "auto",
  };

  const solsStyleShort = {
    background: colorBgContainer,
    // minHeight: 200,
    padding: 12,
    borderRadius: borderRadiusLG,
    // overflowX: "auto",
  };

  // const solsExpanded = false;

  return (
    <Space
      id="ContainerElementID"
      direction="vertical"
      size="middle"
      style={{ display: "flex" }}
    >
      <div id="topcard">
        <CardSample
          mode={"wide"}
          key={"top"}
          meta={problem?.meta}
          description={topCardDescription}
          url={problemLink}
        />
      </div>

      <div id="secondcard"></div>

      {(!!solutionId || !!solutionId) && (
        <div>
          {solutionId === "0" && (
            <CardSample
              key={"top"}
              mode={"wide"}
              meta={solution0?.meta}
              description={solution0Description}
              url={solution0Link}
            />
          )}

          {solutionId === "1" && (
            <CardSample
              key={"top"}
              mode={"wide"}
              meta={solution1?.meta}
              description={solution1Description}
              url={solution1Link}
            />
          )}
        </div>
      )}

      {(!!solution0 || !!solution1) && (
        <div
          id={kind === "problem" ? "secondcard" : ""}
          style={solsExpanded ? solsStyleFull : solsStyleShort}
        >
          <Typography.Title
            level={4}
            style={{
              margin: "0",
            }}
          >
            <em
              onClick={() => {
                setSolsExpanded?.(!solsExpanded);
              }}
            >
              {solsExpanded ? "Все решения" : "Посмотреть все решения"}
            </em>
          </Typography.Title>
          {solsExpanded && (
            <Space
              direction="horizontal"
              align="start"
              size="middle"
              wrap
              style={{
                padding: 0,
                alignItems: "stretch",
              }}
            >
              {(solutionId !== "0" || kind === "problem") && (
                <CardSample
                  vmode="short"
                  key={"top"}
                  meta={solution0?.meta}
                  description={solution0?.title}
                  url={solution0Link}
                />
              )}

              {(solutionId !== "1" || kind === "problem") && (
                <CardSample
                  vmode="short"
                  key={"top"}
                  meta={solution1?.meta}
                  description={solution1?.title}
                  url={solution1Link}
                />
              )}
            </Space>
          )}
        </div>
      )}

      {attrs.map((attr) => {
        const cards = attrCards.filter((card) => card.attr === attr.id);

        if (!cards.length) return null;

        return (
          <div
            key={attr.id}
            id={attr.id}
            style={{
              background: attr.lightColor || colorBgContainer,
              minHeight: 280,
              padding: 12,
              borderRadius: borderRadiusLG,
              // overflowX: "auto",
            }}
          >
            <Typography.Title
              level={4}
              style={{
                margin: "0",
              }}
            >
              <i>Атрибуты: </i>
              {attr.title}
            </Typography.Title>
            <Space
              direction="horizontal"
              align="start"
              size="middle"
              wrap
              style={{
                padding: 0,
              }}
            >
              {cards.map((card, index) => (
                <CardSample key={index} {...card} />
              ))}
            </Space>
          </div>
        );
      })}
    </Space>
  );
};

export default ContentContainer;
