import React, { MouseEventHandler, useCallback, useState } from "react";
import bem from "easy-bem";
const cn = bem("bubble-button");

import { replace, useParams } from "react-router";
import "./bubble-button.css";
import useConfig from "antd/es/config-provider/hooks/useConfig";
import style from "antd/es/affix/style";

type OwnProps = {
  text: string;
  round?: "left" | "right" | "both" | "none";
  onClick?: MouseEventHandler<HTMLButtonElement>;
  backgroundColor?: string;
  borderColor?: string;
};

const BubbleButton: React.FC<OwnProps> = ({
  text,
  round = "both",
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClick = () => {},
  backgroundColor = "transparent",
  borderColor,
}) => {
  const cleanedText = text.trim().replace(/\s+/g, " ");

  const buttonStyle = {
    backgroundColor,
    borderColor,
  };

  return (
    <button
      className={cn({
        round,
      })}
      style={buttonStyle}
      onClick={onClick}
    >
      {cleanedText}
    </button>
  );
};

export default BubbleButton;
