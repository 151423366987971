import { useCallback, useEffect, useState } from "react";
import { scroller } from "react-scroll";

export const useScrollTo = (anchor?: string) => {
  const [element, scrollToElement] = useState(anchor);

  const scrollTo = useCallback((element: string) => {
    scrollToElement(element);
  }, []);

  useEffect(() => {
    let counter = 0;

    const interval = setInterval(() => {
      if (element && document.getElementById(element)) {
        clearInterval(interval);
        scrollToElement(undefined);

        scroller.scrollTo(element, {
          duration: 700,
          isDynamic: true,
          delay: 0,
          smooth: true,
          spy: true,
          // containerId: 'ContainerElementID',
          offset: -90,
        });
      }

      counter++;

      if (++counter > 10) {
        clearInterval(interval);
      }
    }, 200);

    return () => clearInterval(interval);
  }, [element]);

  return { scrollTo };
};
