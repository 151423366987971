import React, { useState } from "react";

import Problem from "./problem/Problem";
import Solution from "./solution/Solution";
import { useParams } from "react-router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createContext, useContext } from "react";

const ClientStateContext = createContext(null);

const queryClient = new QueryClient();

const App: React.FC = () => {
  const { problemId } = useParams<{ problemId: string }>();
  const { solutionId } = useParams<{ solutionId: string }>();

  const [solsExpanded, setSolsExpanded] = useState(false);

  if (solutionId !== undefined && solutionId?.length > 0) {
    return (
      <QueryClientProvider client={queryClient}>
        <Solution
          solutionId={solutionId}
          problemId={problemId}
          setSolsExpanded={setSolsExpanded}
          solsExpanded={solsExpanded}
        />
      </QueryClientProvider>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Problem
        solutionId={solutionId}
        problemId={problemId}
        setSolsExpanded={setSolsExpanded}
        solsExpanded={solsExpanded}
      />
    </QueryClientProvider>
  );
};

export default App;
