import { Avatar, Card, Flex, Space, Typography } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { UserOutlined } from "@ant-design/icons";

import Markdown from "react-markdown";
import { useDataQuery } from "./hooks/useData";
import "./sample-card.css";
const { Text, Title } = Typography;

//@ts-ignore
const CardSample = ({ meta, description, mode = "default", vmode = "default", url = "" }) => {
  const [expanded, setExpanded] = useState(false);
  const [showToggle, setToggle] = useState(false);

  const showButtons = showToggle || !!url;

  const refContainer = useRef(null);
  const refContent = useRef(null);

  const baseHeight = 240;

  const people = useDataQuery("people");

  const openLink = useCallback(() => {
    window.location.href = url;
  }, [url]);

  useEffect(() => {
    if (refContent.current) {
      //@ts-ignore
      const contentHeight = refContent.current?.clientHeight;

      setToggle(contentHeight > baseHeight - 100);
    }
  }, [meta, description, refContent.current, people]);

  if (!people) return <div>Loading people...</div>;
  //@ts-ignore
  const person = people[meta?.author] ?? people["c"];

  const avatarElement = person.avatar ? (
    <Avatar size="large" src={person.avatar} />
  ) : (
    <UserOutlined />
  );

  let width;
  switch (mode) {
    case "half":
      width = "40%";
      break;
    case "quarter":
      width = "20%";
      break;
    case "wide":
      width = "100%";
      break;
    case "default":
    default:
      width = 330;
      break;
  }

  const height =
    vmode === "short" ? "100%" : expanded ? "auto" : `${baseHeight}px`;
  // const height = '100%';

  const minHeight = vmode === "short" ? "unset" : `${baseHeight}px`;

  const toggleText = expanded ? "Свернуть" : "Развернуть";

  const cardClass = expanded ? "card expanded" : "card collapsed";

  return (
    <Card
      ref={refContainer}
      size="small"
      className={cardClass}
      title={
        <Space
          direction="horizontal"
          style={{ padding: "6px 0px", fontWeight: "normal" }}
        >
          <Avatar size="large" icon={avatarElement} />
          <Flex vertical={true}>
            <Text strong>{person.name}</Text>
            <Text>{meta?.date ? meta.date : "13 июля 2024 в 15:45"}</Text>
          </Flex>
        </Space>
      }
      // extra={<HeartOutlined />}
      style={{
        width: width,
        height: height,
        overflowY: "hidden",
        minHeight,
        paddingBottom: "12px",
      }}
    >
      <Typography.Paragraph
        ref={refContent}
        ellipsis={{
          rows: 10,
          // expandable: 'collapsible',
          expanded: true,
          onExpand: (_, info) => setExpanded(info.expanded),
        }}
      >
        {showButtons && (
          <div className="toggler">
            {!!url && (
              <button className="left" onClick={openLink}>
                Открыть
              </button>
            )}

            {showToggle && (
              <button className="right" onClick={() => setExpanded(!expanded)}>
                {toggleText}
              </button>
            )}
          </div>
        )}

        <Markdown
          components={{
            p({ node, className, children, ...props }) {
              return <Typography.Paragraph>{children}</Typography.Paragraph>;
            },
            h1({ node, className, children, ...props }) {
              return (
                <Title level={1} style={{ margin: "0" }}>
                  {children}
                </Title>
              );
            },
            h2({ node, className, children, ...props }) {
              return (
                <Title level={2} style={{ margin: "0" }}>
                  {children}
                </Title>
              );
            },
            h3({ node, className, children, ...props }) {
              return (
                <Title level={3} style={{ margin: "0" }}>
                  {children}
                </Title>
              );
            },
            h4({ node, className, children, ...props }) {
              return (
                <Title level={4} style={{ margin: "0" }}>
                  {children}
                </Title>
              );
            },
            h5({ node, className, children, ...props }) {
              return (
                <Title level={5} style={{ margin: "0" }}>
                  {children}
                </Title>
              );
            },
          }}
        >
          {description}
        </Markdown>
      </Typography.Paragraph>
    </Card>
  );
};

export default CardSample;
