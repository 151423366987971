import React, { useCallback, useState } from "react";
import { Button, ConfigProvider, Radio, Space, Flex } from "antd";
import { scroller } from "react-scroll";

import { useDataQuery } from "./hooks/useData";
import { useParams } from "react-router";
import BubbleButton from "./ui/bubble-button";
import { useScrollTo } from "./hooks/useScrollTo";

type OwnProps = {
  kind?: "problem" | "solution";
  expanded?: boolean;
  setExpanded?: any;
  expanded2?: boolean;
  setExpanded2?: any;
  problemId?: string;
  solutionId?: string;
  setSolsExpanded?: any;
};

const AttrBar: React.FC<OwnProps> = ({
  kind = "problem",
  expanded,
  setExpanded,
  expanded2,
  setExpanded2,
  setSolsExpanded,
  // problemId,
  // solutionId,
}) => {
  const { problemId } = useParams<{ problemId: string }>();
  const { solutionId } = useParams<{ solutionId: string }>();
  const [attrData, setData] = useState<any[] | undefined>();

  const toggle = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded, setExpanded]);

  const toggle2 = useCallback(() => {
    setExpanded2(!expanded2);
  }, [expanded2, setExpanded2]);

  const problemAttrs: any[] = useDataQuery("attrs");
  const solutionAttrs: any[] = useDataQuery("attrs_solution");
  const attrs = kind === "problem" ? problemAttrs : solutionAttrs;

  const cardsProblem = useDataQuery(`cards_problem${problemId}`);

  const problem = useDataQuery(`problem${problemId}`);
  const solutionCount = problem?.solutionCount || 0;

  const cardsSolution: any[] = useDataQuery(
    `cards_problem${problemId}_solution${solutionId}`
  );

  const cards: any[] = kind === "problem" ? cardsProblem : cardsSolution;

  const [position, setPosition] = useState<"start" | "end">("end");
  const style = {
    whiteSpace: "normal",
    lineHeight: "1.6",
    width: "10em",
    minHeight: "4.5em",
    padding: "8px 12px",
    textAlign: "left",
  };

  const { scrollTo } = useScrollTo();
  const setTab = (tab: string, solsExpand = false) => {
    setPosition(tab as "start" | "end");

    scrollTo(tab);
    setSolsExpanded?.(solsExpand);
  };

  const setToTop = () => setTab("topcard");
  const setToSecond = () => {
    setTab("secondcard", kind === "problem");
  };

  const setToSwitch = () => setTab("solutionswitch");

  if (!attrs) return <div>Loading attribute types...</div>;

  if (!cards) return <div>Loading cards...</div>;

  return (
    <Flex gap="18px" justify="space-between" style={{ width: "100%" }}>
      <div>
        <BubbleButton
          backgroundColor={kind === "problem" ? "#FCD757" : "#5C88DA"}
          onClick={kind === "problem" ? setToTop : setToSecond}
          text={kind === "problem" ? "Проблема" : "Решение"}
        />
      </div>

      <div>
        <div>
          {attrs.map((attr, index, array) => {
            const hasCards =
              cards.filter((card) => {
                return card.attr === attr.id;
              }).length > 0;

            const color = hasCards && attr.color;

            return (
              <BubbleButton
                text={attr.title}
                key={attr.id}
                backgroundColor={color || "#ccc"}
                onClick={() => setTab(attr.id)}
                round={
                  index === 0
                    ? "left"
                    : index === array.length - 1
                    ? "right"
                    : "none"
                }
                // value={attr.id}
                // style={style}
              />
            );
          })}
        </div>
      </div>

      <div>
        {/*@ts-ignore*/}
        {!!solutionCount && (
          <BubbleButton
            backgroundColor={kind === "problem" ? "#5C88DA" : "#FCD757"}
            onClick={kind === "problem" ? setToSecond : setToTop}
            text={kind === "problem" ? "Решения" : "Проблема"}
          />
        )}
      </div>
    </Flex>
  );
};

export default AttrBar;
