import React, { useEffect, useState } from "react";
import { ConfigProvider, Layout, Space, theme } from "antd";

import AttrBar from "../attr-bar";
import ContentContainer from "../ContentContainer";
import ProblemSample from "../sample-problem";
import { scroller } from "react-scroll";
import { useScrollTo } from "../hooks/useScrollTo";

import { useLocation } from "react-router-dom";

const { Header, Content, Footer } = Layout;

interface OwnProps {
  id?: string;
  problemId?: string;
  solutionId?: string;
  expanded?: boolean;
  setExpanded?: any;
  solsExpanded?: boolean;
  setSolsExpanded?: any;
}

const Solution: React.FC<OwnProps> = ({
  id,
  problemId,
  solutionId,
  //  expanded,
  // setExpanded,
  solsExpanded,
  setSolsExpanded,
}) => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const [expanded, setExpanded] = useState(false);
  const [expanded2, setExpanded2] = useState(false);

  const { scrollTo } = useScrollTo();

  const setTab = (tab: string, solsExpand = false) => {
    scrollTo(tab);
    setSolsExpanded?.(solsExpand);
  };

  const setToTop = () => setTab("topcard");
  const setToSecond = () => {
    setTab("secondcard");
  };

  const location = useLocation();

  useEffect(() => {
    setTab("secondcard");
  }, [location]);

  useEffect(() => {
    setToSecond();
  }, []);

  useEffect(() => {
    setToSecond();
  }, [solutionId]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Layout: {
            headerBg: "#B7EAFF",
            headerHeight: "auto",
            headerPadding: "8px 16px",
          },
        },
      }}
    >
      <Layout>
        <Header
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            width: "100%",
          }}
        >
          <Space direction="vertical" size="middle" style={{ display: "flex" }}>
            {/* <TopBar /> */}
            <AttrBar
              expanded={expanded}
              setExpanded={setExpanded}
              expanded2={expanded2}
              setExpanded2={setExpanded2}
              kind={"solution"}
            />
          </Space>
        </Header>

        <Content style={{ padding: "16px", background: "none" }}>
          <ContentContainer
            problemId={problemId}
            solutionId={solutionId}
            kind={"solution"}
            colorBgContainer={colorBgContainer}
            borderRadiusLG={borderRadiusLG}
            solsExpanded={solsExpanded}
            setSolsExpanded={setSolsExpanded}
          />
        </Content>
        <Footer style={{ textAlign: "center" }}></Footer>
      </Layout>
    </ConfigProvider>
  );
};

export default Solution;
