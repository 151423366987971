import React, { useEffect, useState } from "react";
import { ConfigProvider, Layout, Space, theme } from "antd";

import AttrBar from "../attr-bar";
import ContentContainer from "../ContentContainer";
import ProblemSample from "../sample-problem";
import { scroller } from "react-scroll";
import { useScrollTo } from "../hooks/useScrollTo";
const { Header, Content, Footer } = Layout;

interface OwnProps {
  problemId?: string;
  solutionId?: string;
  expanded?: boolean;
  setExpanded?: any;
  solsExpanded?: boolean;
  setSolsExpanded?: any;
}

const Problem: React.FC<OwnProps> = ({
  problemId,
  solutionId,
  solsExpanded,
  setSolsExpanded,
  // expanded,
  // setExpanded,
}) => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const [expanded, setExpanded] = useState(false);
  const [expanded2, setExpanded2] = useState(false);

  const { scrollTo } = useScrollTo();

  const setTab = (tab: string, solsExpand = false) => {
    scrollTo(tab);
    setSolsExpanded?.(solsExpand);
  };

  const setToTop = () => setTab("topcard");

  useEffect(() => {
    setToTop();
  }, []);

  return (
    <ConfigProvider
      theme={{
        components: {
          Layout: {
            headerBg: "#FFF2CB",
            headerHeight: "auto",
            headerPadding: "12px 12px",
            footerPadding: "12px 12px",
          },
        },
      }}
    >
      <Layout>
        <Header
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            width: "100%",
          }}
        >
          <Space direction="vertical" size="middle" style={{ display: "flex" }}>
            {/* <TopBar /> */}
            <AttrBar
              expanded={expanded}
              setExpanded={setExpanded}
              problemId={problemId}
              solutionId={solutionId}
              expanded2={expanded2}
              setExpanded2={setExpanded2}
              setSolsExpanded={setSolsExpanded}
            />
          </Space>
        </Header>

        <Content style={{ padding: "12px", background: "none" }}>
          <ContentContainer
            problemId={problemId}
            solutionId={solutionId}
            colorBgContainer={colorBgContainer}
            borderRadiusLG={borderRadiusLG}
            solsExpanded={solsExpanded}
            setSolsExpanded={setSolsExpanded}
          />
        </Content>
        <Footer style={{ textAlign: "center" }}></Footer>
      </Layout>
    </ConfigProvider>
  );
};

export default Problem;
